@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800;900&display=swap');

body{
  font-family: 'Poppins', sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
}

html {
  --scrollbarBG: rgb(228, 228, 228);
  --thumbBG: #FFD600;
  --Primary: #FFD600;
  --LightYellow: #fff8dd;
  --Black: #000000;
  --White: #FFFFFF;
}

a{
  text-decoration: none !important;
}

@font-face {
  font-family: 'UniSans';
  src: url('./Assets/UniSans-Trial-Heavy.woff2') format('woff2');
  }


body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG) !important;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) !important;
  border-radius: 6px !important;
  border: 3px solid var(--scrollbarBG) !important;
}

  /* ------------ Common Button Variables -------------- */
   .qrBtn{
    background: transparent !important;
    border: none !important;
    border-radius: 10px !important;
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
    transition: 0.2s ease-in-out !important;
   }

   .qrBtn:hover{
    transform: translateY(-3px) !important;
    padding: 0px !important;
    cursor: pointer !important;
    color: var(--Primary) !important;
    font-weight: 500 !important;
   }

   .primaryBtn {
    color: var(--Black);
    background: linear-gradient(145deg, #ffdb27, #FFD600) !important;
    padding: 5px 14px !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
    transition: 0.2s !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    transition: all 0.2s !important;
    position: relative !important;
    /* box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1) !important; */
    outline: none !important;
  }

  .primaryBtn:active {
    top: 2px !important;
  }

  .primaryBtn:hover {
    color: var(--Black) !important;
    background: var(--White) !important;
    border: 1px solid var(--Black) !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important; 
  }

  .callNowBtn{
    border: 1px solid transparent !important;
    border-radius: 8px !important;
    background: var(--Black) !important;
    color: var(--White) !important;
    padding: 4px 12px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important; 
  }

  .callNowBtn:hover{
    background: transparent !important;
    color: var(--Black) !important;
    border: 1px solid var(--Black) !important;
  } 

  .errMsg{
    color: red !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin: 3px 0px 0px 5px !important;
    text-align: start !important;
  }

  

/* -------------- Globals Headline and subtitles ------------------- */
  h1{
    font-family: 'UniSans';
    text-align: center;
    margin-bottom: 36px;
  }

  h3{
    font-family: 'UniSans';
    margin-bottom: 2px;
  }

  /* ------------- Menubar CSS -------------------------*/
  .navbarTransperent{
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
  
.navbarBlack{
    background-color: var(--Black);
    transition: 0.3s ease-in-out;
}
  
nav {
   position: fixed;
  left: 0vh;
  right: 0vh;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* background: var(--White); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6.5px 10px 6.5px 16px;
  z-index: 1000;
} 

nav .logo{
  width: 130px;
  cursor: pointer;
}

@media(max-width:576px){
  nav .logo{
    width: 100px;
  }
  nav a img{
    width: 20px !important;
    margin-right: 10px;
  }
}

nav .socials{
  display: flex;
  
}

nav a img{
  width: 24px;
  transition: 0.2s ease-in-out;
  margin-right: 14px;
}

nav a img:hover{
  transform: scale(1.2);
}



nav h5 {
  color: black;
  font-weight: 600;
}
nav h6 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/* nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover {
  transform: translateY(-3px);
  color: white;
} */

.mobile-nav-toggle {
  margin: 5px 0px 5px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* border: 3px solid #fff; */
}

.mobile-nav-toggle .menu-btn__burger {
  width: 35px;
  height: 3px;
  background: var(--Black);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  z-index: 9999;
}

.mobile-nav-toggle .menu-btn__burger::before,
.mobile-nav-toggle .menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 35px;
  height: 3px;
  background: var(--Black);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.mobile-nav-toggle .menu-btn__burger::before {
  transform: translateY(-10px);
}

.mobile-nav-toggle .menu-btn__burger::after {
  transform: translateY(10px);
}

.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
  background: var(--Black);
}

.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
  background: var(--Black);
}


.mobile-nav[data-visible="true"] {
  transform: translateX(0%);
}

.mobile-nav {
  position: fixed;
  --gap: 1rem;
  border-radius: 0px;
  inset: 0 0 0 20%;
  z-index: 1000;
  flex-direction: column;
  padding: 0px;
  transform: translateX(100%);
  transition: 0.3s;
  list-style: none;
  margin: 0;
  background: var(--Primary);
}

.mobile-nav li {
  margin-bottom: 15px;
  color: yellow;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.mobile-nav a.nav-link {
  border: 1px solid var(--Black);
  color: var(--Black);
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 15px;
  font-weight: 500;
}

.mobile-nav a.nav-link.active {
  background: var(--FoxBlack);
  border: 1px solid transparent;
  color: #ffffff;
  font-weight: 500;
}



.Banner {
  display: flex;
  flex-direction: column;
  height: 92vh;
  text-align: center;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   /* Adjust padding as needed */
}

.bottom {
  padding-top: 3vh;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

/* Additional styling, you can adjust as needed */
.Banner .top h1 {
  font-family: 'UniSans';
  margin-bottom: 0px;
  font-size: 2.5em;
}
.Banner .top h1 span{
  color: var(--Primary);
}

.Banner .top p{
  margin-bottom: 0px;
}
.Banner .top .KnowMoreBtn{
  color: var(--Black);
  background: transparent;
  padding: 3px 10px;
  border-radius: 8px;
  border: 1px solid black;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  width: fit-content;
  margin: 0px;
}



.Home .Banner .imgBanner {
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio without cropping */
}

@media(max-width:576px){
  .Banner{
    height: max-content;
    margin-bottom: 30px;
  }
  .Banner .bottom{
    display: none;
  }
}




/* ------------------- Home Hero CSS ----------------- */
   /* .Home .Hero {
      padding-top: 8vh;
      height: 100vh;
      width: 100vw;
      text-align: center;
      position: relative;
    }

    .Home .Hero h1 {
      font-weight: 700;
      text-align: center;
      margin-bottom: -5px;
      letter-spacing: 0.5px;
      font-family: 'UniSans';
    }

    @media(min-width:768px){
      .Home .Hero h1 {
        font-weight: 700;
        text-align: center;
        margin-bottom: -5px;
        letter-spacing: 0.5px;
        font-size: 40px;
        font-family: 'UniSans';
      }
    }

    .Home .Hero h1:first-child {
      color: var(--Primary);
    }

    .Home .Hero h1:last-child {
      color: var(--Primary);
    }

    /* .Home .Hero img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      max-height: 100%;
      width: 74%;
      height: auto;
    } */

    .Home{
      overflow: hidden;
    }
    .Hero{
      height: 100vh;
      padding-top: 8vh;
      text-align: center;
    }

    .Hero h1{
    margin-bottom: 0px;
    }


    .Hero h1 span{
        color: var(--Primary);
    }
 

    .Home .Hero .KnowMoreBtn{
      color: var(--Black);
      background: transparent;
      padding: 3px 10px;
      border-radius: 8px;
      border: 1px solid black;
      transition: 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;
      font-size: 14px;
      width: fit-content;
      margin: 0px;
    }

  

    /* --------------------- Who We Are -------------- */
     .WhoWe{
      background:var(--LightYellow);
      scroll-margin-top: 40px;
      width: 100vw;
     }

     /* ------------------ Features ------------- */
     .features .card,
     .Thread .card,
     .ExpoCards .card{
      border: none;
      border-radius: 12px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      padding: 16px 20px;
      height: 100%;
      transition: 0.3s;
      text-align: center;
     }

     .features .card img,
     .Thread .card img {
      width: 100px;
      height: 100px;
      margin: 0px auto;
     }

     .ExpoCards .card img {
      width: 80px;
      height: 80px;
      margin: 0px auto;
     }
     
     .features .card h5,
     .Thread .card h5,
     .ExpoCards .card h5{
      font-family: "UniSans";
      margin-top: 16px;

     }

     .Thread p{
      margin-top: -3px;
      margin-bottom: 26px;
    }

     .features .card:hover,
     .Thread .card:hover,
     .ExpoCards .card:hover{
      background: var(--Black);
      color: white;
      transform: translateY(-10px);
     }

/*------------------- Services ---------------------- */
    .services{
      background: var(--Black);
      text-align: center;
    }

    .services h1{
      color: var(--White);
    }

    /* .services .row .col-md-3{
      margin-bottom: 34px !important;
    } */

    .services img{
      width: 90px;
      height: 90px;
    }

    .services h4{
      font-family: 'UniSans';
      color: var(--White);
      margin-top: 12px;
    }

    @keyframes slide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    
    .logos {
      overflow: hidden;
      padding: 10px 0px 0px 0px;
      background: white;
      /* white-space: nowrap; */
      position: relative;
    }
    
    .logos:before,
    .logos:after {
      position: absolute;
      top: 0;
      width: 200px;
      height: 100%;
      content: "";
      z-index: 2;
    }
    
    .logos:before {
      left: 0;
      background: linear-gradient(to left, rgba(190, 190, 190, 0), white);
    }
    
    .logos:after {
      right: 0;
      background: linear-gradient(to right, rgba(190, 190, 190, 0), white);
    }
    
    /* .logos:hover .logos-slide {
      animation-play-state: paused;
    } */
    
    .logos-slide {
      display: inline-block;
      animation: 80s slide infinite linear;
      white-space: nowrap;
    }
    
    .logos-slide img {
      height: 90px;
      margin: 0 35px;
      transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
    }


    @media(max-width:576px){
      .logos-slide img {
        height: 60px;
        margin: 0 25px;
      }

      .logos:before,
      .logos:after {
        width: 150px;
        height: 100%;
      }

    }    
    /* .logos-slide img:hover {
      transform: scale(1.2);
    } */

 /*----------------- Socials ------------ */
  .socials p{
    text-align: center;
    margin-top: -6px;
  }

  .socials .icons{
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .socials .icons img{
    width: 40px;
    height: 40px;
    transition: 0.2s ease-in-out;
  }

  .socials .icons img:hover{
    transform: translateY(-5px) !important;
  }

  .socials .label{
    font-size: 11px;
    font-weight: 500;
    color: #757575;
    text-align: center;
    margin: 8px 0px 0px 0px;
    letter-spacing: 0.2px;
  }

/* ---------------- Footer ----------------------*/
    footer{
      background: black;
      color: var(--Primary);
      padding: 20px 0px 30px 0px;
    }

    footer .footerTop{
      background: linear-gradient(90deg,#ffd60c,var(--Primary));
      border-radius: 8px;
      margin-top: -50px;
      width: 50%;
    }



    @media(max-width:768px){
      footer .footerTop{
        margin-top: -80px;
        width: 75%;
      }
    }

    @media(max-width:992px){
      footer .footerTop{
        margin-top: -60px;
        width:75%;
      }
    }

    footer .footerTop h5{
      color: var(--Black);
      margin-bottom: 0px;
      margin-top: 2px;
    }

    footer img{
      width: 90px;
    }

    footer p{
      color: var(--White);
    }

    footer .sitemap{
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }

    footer .sitemap a{
      transition: 0.3s !important;
      color: var(--White);
    }

    footer .sitemap a:hover{
      transform: translateX(-10px);
      color: var(--Primary);
    }

    footer .sitemap div{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    @media (min-width:992px) {
      footer .sitemap a{
        width: max-content;
      }
      footer .sitemap p:hover{
        color: var(--Primary);
      }
    }

    footer .sitemap div p{
      margin-bottom: 0px;
    }

    footer button{
      animation: shake-animation 3s ease infinite;
      /* animation: shake-animation 4.72s ease infinite; */
      transform-origin: 50% 50%;
    }

    @keyframes shake-animation {
      0% { transform:translate(0,0) }
      1.78571% { transform:translate(5px,0) }
      3.57143% { transform:translate(0,0) }
      5.35714% { transform:translate(5px,0) }
      7.14286% { transform:translate(0,0) }
      8.92857% { transform:translate(5px,0) }
      10.71429% { transform:translate(0,0) }
      100% { transform:translate(0,0) }
   }


/* ------------------------ Pet Expo ------------------------ */
  .PetExpo .map{
    width: 100%;
  }

  /* .PetExpo .map img{
    height: 100%;
    width: 100%;
  } */

  .PetExpo .map .left,
  .PetExpo .map .right{
    overflow: hidden;
  }



  @media(max-width:992px){
    @keyframes scrollLeft {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  
    @keyframes scrollRight {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(100%);
      }
    }


    .PetExpo .map .leftScroll{
      gap:40px;
      animation:  scrollLeft 20s infinite linear;
    }
  
    .PetExpo .map .rightScroll{
      gap:40px;
      animation:  scrollRight 20s infinite linear;
    }

    .PetExpo .map .leftScroll img,
    .PetExpo .map .rightScroll img{
      width: 50px;    
    }
  
  }


 @media(min-width:992px){
  @keyframes scrollUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes scrollDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }

  .PetExpo .map .leftScroll{
    gap:50px;
    max-height: 70vh;
    animation:  scrollUp 20s infinite linear;
  }

  .PetExpo .map .rightScroll{
    gap:50px;
    max-height: 70vh;
    animation:  scrollDown 20s infinite linear;
  }

  .PetExpo .map .leftScroll img,
  .PetExpo .map .rightScroll img{
    width: 80px;    
  }

}


  .PetExpo .Register {
    background: var(--Primary);
  }

 .PetExpo .Register input.form-control{
  background-color: var(--Primary);
  border: 1px solid var(--Black);
 } 

/* ------------------------------ FAQs Accordion ------------------------------*/

.faq h1{
  font-weight: 700;
}
.faq .accordion-item{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 border: none;
 border-radius: 0px 0px 12px 12px !important;
 margin-bottom: 15px;
}
.faq .accordion-button{
  border-radius: 0px 0px 12px 12px !important;
  border: none;
}
.faq .accordion-button:not(.collapsed){
  color: inherit;
  background:black;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--Primary);
}

.faq button.accordion-button:not(.collapsed)::after{
 filter:brightness(0%) invert(100%);
 color: white;
}

.faq .accordion-button:focus{
  box-shadow: inherit;
}











.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


